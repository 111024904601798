import React, {useState, useEffect} from 'react';
import { StylesProvider, jssPreset, ThemeProvider, createTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {he, en} from '../Text';

import StyledTextField from '../components/StyledTextField';
import StyledButton from '../components/StyledButton';
import StyledNextButton from "../components/StyledNextButton";

import X from '../assets/X.svg';

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const Header = styled.div`
  position: absolute;
  top: 5.5vh;
  font-size: 11.4vmin;
  font-family: ${(props) => props.font};
  color: white;
`;
const Info = styled.img`
  position: absolute;
  top: 17vh;
  height: 10vh;
  widht: 10vh;
  max-height: 69.5;
  max-width: 69.5;
`;
const Text = styled.div`
  font-size: 3.4vmin;
  font-family: ${(props) => props.font};
  color: white;
  position: absolute;
  top: 36.5vh;
  max-width: 50vh;
`;
const Exit = styled.img`
  position: absolute;
  top: 3vh;
  right: 3vh;
  cursor:pointer;
`;

const TutorialPage = (props) => {
    const [language, setLanguage] = useState(he);
    const [currentSlide, setCurrentSlide] = useState(0);

    const theme = createTheme({
        direction: language == en ? 'ltr' : 'rtl',
      });
      
    const redirectRoute = (route, data = {}) => {
        props.history.push({
          pathname: route,
          state: data,
        });
      }  

    const handleNext = () => {
        if (currentSlide < language.Tutorial.instructions.length) {
            setCurrentSlide(currentSlide + 1);
          }
    }

    const handleDone = () => {
        redirectRoute("/")
    }

    return(
        <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
            <div className="wide-box fade-middle" dir={language == en ? "ltr" : "rtl"}>
                <div className="row" style={{justifyContent: 'flex-start', width: ' max(280px, 100%)'}} >
                <Exit src={X} onClick={() => redirectRoute('/')} />
                </div>
                <Header font={language.fonts.thin}>{language.Tutorial.tutorial}</Header>
                <Text font={language.fonts.reg}>{language.Tutorial.instructions[currentSlide]}</Text>
 
                <StyledNextButton
                    dotsCount={language.Tutorial.instructions.length}
                    language={language}
                    text={ currentSlide < language.Tutorial.instructions.length ? language.Tutorial.next : language.Tutorial.done }
                    width="17.1vw"
                    handleNext={currentSlide < language.Tutorial.instructions.length ? handleNext:handleDone}
                    currentSlide={currentSlide}
                    onSwitch={(v) => {
                    setCurrentSlide(v + 1);
                    }}
                />
            </div>
        </ThemeProvider>
      </StylesProvider>
  
    )
}

export default TutorialPage;