import React, {useState, useEffect} from 'react';
import { StylesProvider, jssPreset, ThemeProvider, createTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import {create} from 'jss';
import rtl from 'jss-rtl';

// import Auth from '../utils/Auth';
import LoginForm from '../components/Login/LoginForm';
import StyledTextField from '../components/StyledTextField';
import SimpleModal from '../components/SimpleModal';
import BottomSection from '../components/Login/BottomSection';
import {he, en} from '../Text';

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const LoginButton = styled.button`
  width: 24.2vw;
  height: 4.5vh;
  border-radius: 5px;
  background-color: #47cdd9 !important;
  color: #000000;
  border: 0;
  font-size: 2.2vmin;
  font-family: ${(props) => props.font};
  &:hover {
    background-color: #47cdd9;
    color: #000000;
  }
`;

const LoginPage = (props) => {
    const [language, setLanguage] = useState(he);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [modalText, setModalText] = useState('');

    const theme = createTheme({
        direction: language == en ? 'ltr' : 'rtl',
      });

    const redirect = () => {
        /*
        let user = localStorage.getItem('user');
        if (user == null) return;
        user = JSON.parse(user);
        if (user.needTutorial || user.needTutorial == undefined) {
          redirectRoute('/Wizard');
        } else {
          redirectRoute('/');
        }
        */
      }

    const validateForm = () => {
        return email.length > 0 && password.length > 0;
      }

    const afterAuth = () => {
        redirect();
      }

    const reset=() =>{
        localStorage.clear();
      }

    const handleError = (e) =>{
        reset();
        console.log(e.message);
        setModalText(e.message);
        setModalShow(true);
      }

    const handleSubmit  = () => {
        if (!validateForm()) {
          setModalShow(true);
          return;
        }
//        Auth.login(password, email, afterAuth, handleError);
    }

    const redirectRoute = (route, data = {}) => {
        props.history.push({
          pathname: route,
          state: data,
        });
      }

    console.log("login")
    return (
        <div>
        <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
            <div
                className="box"
                style={{
                position: 'relative',
                backgroundColor: ' rgba(19, 43, 62, 0.9)',
                }}
                dir={language == en ? 'ltr' : 'rtl'}
            >
                {/*
                <div style={{position: 'absolute', top: '9.7vh'}}>
                    
                <FaceRecAnimation
                    animationFlag={animationFlag}
                    faceRecStatus={faceRecStatus}
                    // startScan={startFaceRec}
                    onCancle={stopFaceRec}
                />
                </div>
                <StyledTextField
                style={{position: 'absolute', top: '35vh'}}
                variable={phone}
                type="tel"
                label={language.Login.phone_label}
                updateVariable={setPhone}
                language={language}
                />
                <LoginButton
                style={{
                    position: 'absolute',
                    top: '47.2vh',
                    fontFamily: language.fonts.med,
                    fontSize: '2.2vmin',
                }}
                font={language.fonts.med}
                size="lg"
                block
                type="submit"
                onClick={()=>{}}
                >
                {language.Login.login_face_button}
                </LoginButton>
                {/* <Instructions font={language.fonts.bold}>
                {language.Login.instructions.split('\n').map((i, index) => {
                    return <p key={index}>{i}</p>;
                })}
                </Instructions> 
                */}
                <LoginForm
                style={{position: 'absolute'}}
                handleSubmit={handleSubmit}
                updateEmail={(v) => setEmail(v)}
                updatePassword={(v) => setPassword(v)}
                language={language}
                />
                <SimpleModal
                open={modalShow}
                handleClose={() => setModalShow(false)}
                text={modalText}
                language={language}
                >
                <div></div>
                </SimpleModal>
                <BottomSection
                language={language}
                onRegister={() => redirectRoute('/Register')}
                onForgot={() => redirectRoute('/Forgot-Password')}
                />
            </div>
            </ThemeProvider>
        </StylesProvider>
    </div>
    );  
};

export default LoginPage;