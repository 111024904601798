import './App.css';

import React from 'react';
import { Collapse, CssBaseline } from "@material-ui/core";
import {
  HashRouter as Router ,
  Switch as RouterSwitch,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import MainMenu from "./pages/MainMenu";
import UsersPage from "./pages/UsersPage";
import ResearchPage from "./pages/ResearchPage";
import UserInfoPage from "./pages/UserInfoPage";
import TutorialPage from "./pages/TutorialPage";
import QuestionnairePage from "./pages/QuestionnairePage";
import {ApsatVideoPage, ApsatAudioPage} from "./pages/Apsat";
import OddballPage from "./pages/OddballPage";

const ITEMS_PER_STACK = 3;

function App() {
  const token = 1;
  const history = useHistory();

  return (<Router>
    <div className="body" style={{ display: "flex" }}>
      <CssBaseline />
      <RouterSwitch>
        {!token ? (
          <LoginPage />
        ) : (
          <Switch>
            {/* TODO - add redirect depeneding on the user Conginto group  */}
            <Route exact path="/mainmenu" component={MainMenu} />
            <Route exact path="/users" component={UsersPage} />
            <Route exact path="/research" component={ResearchPage} />
            <Route exact path="/userinfo" component={UserInfoPage} />
            <Route exact path="/tutorial" component={TutorialPage} />
            <Route exact path="/questionnaire" component={QuestionnairePage} />
            <Route exact path="/apsat-visual" component={ApsatVideoPage} />
            <Route exact path="/apsat-audio" component={ApsatAudioPage} />
            <Route exact path="/oddball" component={OddballPage} />
            <Redirect to="/mainmenu" />
          </Switch>
        )}
      </RouterSwitch>
    </div>
    </Router>
  );
}

export default App;