export const he = {
  fonts: {
    light: 'heebolight',
    med: 'heebomed',
    thin: 'heebothin',
    reg: 'heeboreg',
    bold: 'heebobold',
  },
  Login: {
    email_label: 'אימייל',
    password_label: 'סיסמה',
    phone_label: 'מספר טלפון',
    login_button: 'התחברות',
    login_face_button: 'התחברות באמצעות זיהוי פנים',
    forgot_password: 'שכחת סיסמה?',
    register: 'הרשמה',
    instructions: 'התחבר באמצעות זיהוי פנים או הכנס את פרטי המשתמש שלך',
  },
  mainmenu: {
    tutorial:'הדרכה',
    start_practice:'התחלי אימון',
    start_test:'התחלי בדיקה',
    users_admin:'ניהול משתמשים',
    research_results:'תוצאות הבדיקות',
  },
  Register: {
    first_name_label: 'שם פרטי',
    id_label: 'תעודת זהות/מספר אישי',
    terms: 'אני מסכים לתנאי השימוש ומדיניות הפרטיות',
    next: 'הבא',
    take_photo: 'צלם תמונה',
    dob: 'תאריך לידה',
    male: 'זכר',
    female: 'נקבה',
    email_label: 'כתובת דואר אלקטרוני',
    for_password_reset: 'לצורך שחזור סיסמה',
    password_label: 'בחר את הסיסמה שלך',
    password_check_label: 'הכנס את הסיסמה שוב',
    add_facial_login: 'הוסף התחברות בעזרת זיהוי פנים',
    add_facial_login_instruction:
      'הסתכל אל עבר מצלמת הדאשבורד של אדם, וודא כי פניך חשופות ונראות ברור',
    finish: 'סיום',
    start_scan: 'התחל סריקה',
    done: 'סיום',
    great: 'מעולה!',
    add_mask: 'הוסף סריקה עם מסיכה',
    after_scan: 'תוכל להיכנס רק על ידי הסתכלות עלינו',
    add_mask_instruction: 'הקורונה עדיין בסביבה? הוסף תמונה עם מסכה',
    shot_with_mask: 'צלם עוד תמונה עם מסיכה',
    thank_you: 'תודה',
    register_end: 'תהליך ההרשמה שלך הושלם',
    token_header: 'האם קיבלת קוד הרשמה?',
    token_label: 'הכנס קוד כאן',
    no_code: '(אין קוד? פשוט לחץ הבא)',
    face_scan1: `Please look directly at\n
    the camera located below`,
    face_scan2: `Aweome!\n
    Now get a bit closer\n
   and look at the camera`,
    face_scan3: `Great!\n
   Now get even further\n
  than before`,
    face_scan4: `Brilliant!\n
  Now Get to normal position
  And look to the left of the screen`,
    face_scan5: `Amazing!\n
  Almost finished,\n
  Now look to the right of the scren`,
  },

  Forgot: {
    forgot_title: 'שכחתי סיסמה',
    forgot_instructions:
      'על מנת לאפס סיסמה, אנא  הזן את כתובת הדואר האלקטרוני שלך ',
    email_label: 'כתובת דואר אלקטרוני',
    continue: 'המשך',
    thank_you: 'תודה רבה',
    forgot_end_text:
      'אם קיים חשבון לכתובת הדואר נשלח אלייך הודעה עם קוד לאיפוס סיסמה',
    enter_code: 'אנא הזן את הקוד לאיפוס הסיסמה',
    new_password_text: 'בחר סיסמה חדשה',
    confirm_password_text: 'הזן שוב את הסיסמה החדשה',
    reset_success: 'הסיסמא שונתה',
    back_button_text: 'חזור לעמוד הראשי',
  },
  preCheckup: {
    next: 'הבא',
    instruction: 'נתחיל בכמה שאלות',
    cant_hear: 'לא מצליח לשמוע את הציפצוף?',
    play_again: 'השמע שוב',
    state_name: 'אנא הגד את שמך בקול רם לאחר השמע הציפצוף',
    state_date: 'אנא אמור בקול רם את\n  השעה הנוכחית:',
    state_text: `אנא חזור בקול רם \nעל המשפט הבא:\n "אני מרגיש כשיר לנהיגה"`,
    done_text: `\n  תודה לך ,{name}
      אנא הסתכל אל מרכז המסך\n
      עקוב אחר נורות הלד כאשר הן דלוקות\n
      והחזר את מבטך אל מרכז המסך\n
      בזמן שנורות הלד כבויות`,
    startCheckup: 'התחל בדיקה',
  },
  Checkup: {
    starting: 'בדיקה מתחילה..',
    pause: 'עצור',
    tutorial: 'הדרכה',
    last_checkup: 'תוצאות אחרונות',
    account: 'חשבון משתמש',
    checkup_result: 'תוצאת בדיקה',
    checkup_result_positive: 'ביצוע תקין',
    done: 'סיים',
    checkup_result_repeat: 'אנא חזור על הבדיקה',
    repeat: 'חזור',
    abort: 'בטל',
    checkup_result_negative: 'ביצוע חריג',
    repeat_checkup: 'חזור על הבדיקה',
    logout: 'התנתקות',
  },
  Tutorial: {
    tutorial: 'הדרכה',
    start_button_label: 'התחל הדרכה',
    pre_start_text: 'הבדיקה תחל מיד לאחר ההדרכה. אנא עקוב אחר ההוראות הדרכה',
    next: 'הבא',
    done: 'סיום',
    dont_show_radiobox: 'אל תראה הדרכה זו בעתיד',
    instructions: [`במטלה הבאה יוצגו ספרות על המסך.\n ספרה אחת בכל פעם. `,
      `עליך לסכום כל ספרה עם זו שהופיעה מיד לפניה\n וללחוץ על המקש המתאים לסכום בלוח המקשים בתחתית המסך.`,
      `אם למשל מוצגות הספרות: 1, ואחריה 5, \nהסכום הוא (1+5)=6 \nועליך ללחוץ על המקש המתאים לספרה 6.`,
      `ברצף הספרות: 4 , 3, 8, 2 \n עליך ללחוץ: 7, 11, 10.`,
      `הקפד.י ללחוץ כמה שיותר מהר\n אך גם להיות כמה שיותר מדויק.ת`,
    ],
    start_checkup_button_label: 'החל בדיקה',
    repeat_tutorial: 'חזור על ההדרכה',
  },
  Experiment: {
    starting: 'מייד מתחילים',
    practice: 'אימון',
    thankyou: 'תודה',
  },
  UsersTable: {
    exit: "חזור",
    name: "שם",
    test_subject: "מטופל",
    admin: "מנהל",
    researcher: "חוקר",
    enabled: "פעיל",
    start_date: "תאריך תחילה",
    last_test: "בדיקה אחרונה",
    num_tests: "סהכ בדיקות",
    actions: "פעולות",
  },
  Errors_and_validators: {},
};
export const en = {
  fonts: {
    light: 'robotolight',
    med: 'robotomed',
    thin: 'robotothin',
    reg: 'robotoreg',
    bold: 'robotobold',
  },
  Login: {
    email_label: 'Email',
    password_label: 'Password',
    login_button: 'Login',
    phone_label: 'Phone number',
    login_face_button: 'Login with face recognition',
    forgot_password: 'FORGOT YOUR PASSWORD?',
    register: 'REGISTER',
    instructions: 'Login with face recognition OR Type your user creds',
  },
  mainmenu: {
    tutorial:'Tutorial',
    start_practice: 'Start Practice',
    start_test:'Start Test',
    users_admin:'Users Admin',
    research_results:'Research Results',
  },
  Register: {
    first_name_label: 'First Name',
    id_label: 'ID',
    terms: 'I AGREE TO TERMS AND PRIVACY POLICY',
    next: 'NEXT',
    take_photo: 'TAKE A PHOTO',
    dob: 'Date of Birth',
    male: 'male',
    female: 'female',
    email_label: 'Email',
    for_password_reset: 'for password reset',
    password_label: 'Choose your password',
    password_check_label: 'Re-Enter your passowrd',
    add_facial_login: 'Add facial login',
    add_facial_login_instruction: `Please look towards ADAM dashboard camera and
      make sure all parts of your face are not covered by any object and are clearly visible`,
    finish: 'FINISH',
    start_scan: 'START SCAN',
    done: 'DONE',
    great: 'Great!',
    add_mask: 'ADD FACE WITH MASK',
    add_mask_instruction:
      'COVID is still around? Take another shoot with a mask',
    after_scan: 'You will be able to get in just by looking at us :)',
    shot_with_mask: 'Take another shoot with a mask',
    thank_you: 'Thank You',
    register_end: 'Your Registration is Now Complete.',
    face_scan1: `Please look directly at\n
    the camera located below`,
    face_scan2: `Aweome!\n
    Now get a bit closer\n
   and look at the camera`,
    face_scan3: `Great!\n
   Now get even further\n
  than before`,
    face_scan4: `Brilliant!\n
  Now Get to normal position
  And look to the left of the screen`,
    face_scan5: `Amazing!\n
  Almost finished,\n
  Now look to the right of the scren`,
  },
  Forgot: {
    forgot_title: 'Forgot Passowrd',
    forgot_instructions:
      'Please enter your email below to reset your password ',
    email_label: 'EMAIL',
    continue: 'CONTINUE',
    thank_you: 'Thank you',
    forgot_end_text:
      'If an account exists for the supplied e-mail you will get an e-mail With a code to reset your password',
    back_button_text: 'BACK TO LOGIN',
    enter_code: 'Please enter your code below',
    new_password_text: 'Choose your new password',
    confirm_password_text: 'Please re-enter your new password',
    reset_success: 'Password Reset Successfully',
  },
  Checkup: {
    starting: 'Checkup starting..',
    pause: 'PAUSE ',
    tutorial: 'TUTORIAL',
    last_checkup: 'LAST CHECKUP RESULT',
    account: 'ACCOUNT INFORMATION',
    checkup_result: 'CHECKUP RESULT',
    checkup_result_positive: 'Standard Performance, You are good to go!',
    done: 'DONE',
    checkup_result_repeat: 'Please repeat',
    repeat: 'REPEAT',
    abort: 'ABORT',
    checkup_result_negative: 'Deviating Performance',
    repeat_checkup: 'REPEAT CHECKUP',
    logout: 'LOG OUT',
  },
  Tutorial: {
    start_button_label: 'START TUTORIAL',
    pre_start_text:
      'Driver Cognitive Checkup will start soon please follow the insturctions',
    next: 'NEXT',
    dont_show_radiobox: "DON'T SHOW THIS AGAIN",
    instructions_1: `After this short tutorial\n
      a pre-shift checkup will commence. `,
    instructions_2: `Before the checkup will start\n
      you will be asked to read the a few\n
      statements out loud`,
    instructions_3: `When checkup starts\n
      look at the center of the screen`,
    instructions_4: `When you spot a blinking LED follow at it with you eyes\n
      (please refine from tilting you head).`,
    instructions_5: `When the LED’s are turned-off please return your gaze to the center of the screen.`,
    instructions_6: `Afterwards you will be asked to watch a short video clip`,
    start_checkup_button_label: 'START CHECKUP',
    repeat_tutorial: 'REPEAT TUTORIAL',
  },
  preCheckup: {
    next: 'NEXT',
    instruction: 'Now we’ll be asking a few questions',
    cant_hear: 'Can’t hear the beeps?',
    play_again: 'Play again',
    state_name: 'Please state your name out load after the beep',
    state_date: 'Please state current time\n',
    state_text: `Please repeat the following statement:\nI feel eligible to drive`,
    done_text: `Thank you, {name},\n
      Please look at the screen center\n
      and follow the LED lights when they\n
      appear, return your look to the center\n
      when the LED’s are turned-off`,
    startCheckup: 'START CHECKUP',
  },
  UsersTable: {
    name: "name",
    test_subject: "patient",
    admin: "admin",
    researcher: "researcher",
  },
  Errors_and_validators: {
    invalid_passwod: 'Passowrd is invalid',
    invalid_email: 'Email is invalid',
    invalid_id: 'ID is invalid',
    invalid_dob: 'Date of Birth is invalid',
  },
};
