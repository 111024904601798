import React, {useState, useEffect} from 'react';
import { StylesProvider, jssPreset, ThemeProvider, createTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {he, en} from '../Text';

import StyledTextField from '../components/StyledTextField';
import StyledButton from '../components/StyledButton';

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const MainMenu = (props) => {
  const [language, setLanguage] = useState(he);

  const theme = createTheme({
      direction: language == en ? 'ltr' : 'rtl',
    });

  const redirectRoute = (route, data = {}) => {
      props.history.push({
        pathname: route,
        state: data,
      });
    }

  return (
    <div className="box">
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <div
              className="box"
              style={{
                position: 'relative',
                backgroundColor: ' rgba(19, 43, 62, 0.9)',
              }}
              dir={language == en ? 'ltr' : 'rtl'}
          >
            <StyledButton
                style={{
                    position: 'absolute',
                    top: '17.2vh',
                    fontFamily: language.fonts.med,
                    fontSize: '2.2vmin',
                }}
                font={language.fonts.med}
                size="lg"
                block
                onClick={()=>{ redirectRoute("tutorial");}}
                >
                {language.mainmenu.tutorial}
            </StyledButton>

            <StyledButton
                style={{
                    position: 'absolute',
                    top: '27.2vh',
                    fontFamily: language.fonts.med,
                    fontSize: '2.2vmin',
                }}
                font={language.fonts.med}
                size="lg"
                block
                onClick={()=>{ redirectRoute("apsat-visual", {is_practice:true, test_length:36});}}
                >
                {language.mainmenu.start_practice}
            </StyledButton>       

            <StyledButton
                style={{
                    position: 'absolute',
                    top: '37.2vh',
                    fontFamily: language.fonts.med,
                    fontSize: '2.2vmin',
                }}
                font={language.fonts.med}
                size="lg"
                block
                onClick={()=>{ redirectRoute("apsat-visual", {is_practice:false});}}
                >
                {language.mainmenu.start_test}
            </StyledButton>     

            <StyledButton
                style={{
                    position: 'absolute',
                    top: '47.2vh',
                    fontFamily: language.fonts.med,
                    fontSize: '2.2vmin',
                }}
                font={language.fonts.med}
                size="lg"
                block
                onClick={()=>{ redirectRoute("/users"); }}
                >
                {language.mainmenu.users_admin}
            </StyledButton>

            <StyledButton
                style={{
                    position: 'absolute',
                    top: '57.2vh',
                    fontFamily: language.fonts.med,
                    fontSize: '2.2vmin',
                }}
                font={language.fonts.med}
                size="lg"
                block
                onClick={()=>{ redirectRoute("/research"); }}
                >
                {language.mainmenu.research_results}
            </StyledButton>
          </div>
        </ThemeProvider>
      </StylesProvider>
    </div>


  );
};
  
  export default MainMenu;