import styled from "styled-components";

const StyledButton = styled.button`
  width: 24.2vw;
  height: 4.5vh;
  border-radius: 5px;
  background-color: #47cdd9 !important;
  color: #000000;
  border: 0;
  font-size: 2.2vmin;
  font-family: ${(props) => props.font};
  &:hover {
    background-color: #47cdd9;
    color: #000000;
  }
`;

export default StyledButton;