import React, {useState, useEffect, Fragment, useRef} from 'react';
import { StylesProvider, jssPreset, ThemeProvider, createTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {he, en} from '../Text';

import Fader from '../components/Fader'

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const ApsatAudioPage = (props) => {
    return(
        <></>
    )
}

const Stimulus = styled.div`
  position: absolute;
  left: 0%;
  width: 100%;
  top: 15.5vh;
  font-size: 22vmin;
  font-family: ${(props) => props.font};
  color: white;
  text-align: center;
`;

const StyledButton = styled.button`
  width: 4.5vw;
  height: 8vh;
  border-radius: 5px;
//  background-color: #222 !important;
  color: #fff;
  border: 2;
  font-size: 2.2vmin;
  font-family: ${(props) => props.font};
  &:hover {
    background-color: #47cdd9;
    color: #000000;
  }
`;

const Banner = styled.div`
  position: absolute;
  top: 1vh;
  left: 3vh;
  color: yellow;
  font-size: 5vmin;
  font-family: ${(props) => props.font};
`;

const KeypadDiv = styled.div`
  position: absolute;
  top: 60%;
  left: 0%;
  width: 100%;
  align-items: center;
`;

//==============================================================================

const Keypad = (props) => {
    var indents = [];
    indents.push(<StyledButton style={{border:0, color:"#000", backgroundColor:"#000"}} id={1} key={1} onClick={(e)=>{}}>{'.'}</StyledButton>);

    for (var i = 2; i < 20; i++) {
        var bg = "#222";
        if (props.is_practice && (props.stimulus.answer==props.stimulus.value+props.stimulus.prev) && (i==props.stimulus.answer))
          bg = "green";
        if (props.is_practice && (props.stimulus.answer>0) && (props.stimulus.answer!=props.stimulus.value+props.stimulus.prev) && (props.stimulus.answer==i))
          bg = "red"
      indents.push(<StyledButton id={i} key={i} style={{backgroundColor:bg}} onClick={(e)=>{props.onClick(e)}}>{i}</StyledButton>);
      if (i % 5 == 0)
        indents.push(<br key={"br"+i}/>)
    }
    indents.push(<StyledButton style={{border:0, color:"#000", backgroundColor:"#000"}} id={20} key={20} onClick={(e)=>{}}>{'.'}</StyledButton>);
    
    return (
        <KeypadDiv>{indents}</KeypadDiv>
    )
}

//==============================================================================

const ApsatVideoPage = (props) => {
    const vidRef = useRef(null)

    const [language, setLanguage] = useState(he)
    const [stimulus, setStimulus] = useState({value:0, prev:0, show:false, answer:0, count:0, stimulus_duration:350, isi_duration:3000 })
    const [experimentState, setExperimentState] = useState("countdown")

    const is_practice = props.location.state.is_practice
    const test_length = props.location.state.test_length || 200
    var test_log = []

    
    //--------------------------------------------------------------------------
    const saveResultsToFile= (filename) => {
      // caluculated fields
      var count = 0
      for (var i in test_log) {
        test_log[i].is_correct = parseInt(test_log[i].answer)==test_log[i].value+test_log[i].prev
        test_log[i].count_correct = test_log[i].is_correct ? count+1 : 0
        test_log[i].answer_duration = test_log[i].answer_timestamp - test_log[i].stimulus_timestamp
        count = test_log[i].count_correct
      }
                                          
                                        
      var fields = [ "count", "value", "prev", "answer", "is_correct", "count_correct", "stimulus_timestamp", "answer_timestamp", "stimulus_duration", "isi_duration", "answer_duration"]; // Object.keys(test_log[0])
      var replacer = function(key, value) { return value === null ? '' : value } 
      var csv = test_log.map(function(row){
        return fields.map(function(fieldName){
          return JSON.stringify(row[fieldName], replacer)
        }).join(',')
      })
      csv.unshift(fields.join(',')) // add header column
      csv = csv.join('\r\n');

      const blob = new Blob([csv], {type: 'text/csv'});
      var element = document.createElement('a');
      element.setAttribute('href', window.URL.createObjectURL(blob));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    //--------------------------------------------------------------------------
    const showStimulus = function()  {
        var s = null
        setStimulus((stimulus) => {
            s = stimulus
            const is_correct = parseInt(stimulus.answer) == stimulus.value + stimulus.prev
            return {show:true, 
                    stimulus_timestamp: Date.now(),
                    prev:stimulus.value, 
                    value: 1 + Math.floor(Math.random() * 8), 
                    answer:0,
                    count:stimulus.count+1,
                    stimulus_duration:350,
                    isi_duration: is_practice || stimulus.count <= 1 ? 3000 : stimulus.isi_duration + (is_correct ?  - 20 : 20)
                  }
                });

        if (s && s.count > 0)
            test_log.push(s)                

        if (s && s.count>=test_length) {
            setExperimentState("thankyou")
            setTimeout(()=>{redirectRoute("/")},3000)
            saveResultsToFile("apsat test result.csv")
        } else {
          setTimeout( showKeypad, s.stimulus_duration);
        }
    }

    //--------------------------------------------------------------------------
    const showKeypad = () => {
        var s;
        setStimulus((stimulus) => {
          s=stimulus; 
          return({...stimulus, show:false})
        })
        setTimeout( showStimulus, s.isi_duration);
    }

    //--------------------------------------------------------------------------
    const handleAnswer = (e)=>{
        setStimulus((stimulus) => ({...stimulus, answer:e.target.id, answer_timestamp:Date.now() }))
    }

    //--------------------------------------------------------------------------
    useEffect(() => {
        setTimeout(()=>{
            setExperimentState("experiment"); 
            showStimulus()
            }, 4000);
        }, []);

    //--------------------------------------------------------------------------
    const theme = createTheme({
        direction: language == en ? 'ltr' : 'rtl',
      });
      
    //--------------------------------------------------------------------------
    const redirectRoute = (route, data = {}) => {
        props.history.push({
          pathname: route,
          state: data,
        });
      }  

    //--------------------------------------------------------------------------
    return(
        <Fragment>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={theme}>
            <div className="black ">
              {{
                  countdown: (
                    <Fader text={language.Experiment.starting}></Fader>
//                    <Stimulus className="fade-out">{language.Experiment.starting}</Stimulus>
                  ),
                experiment: (<>
                                { is_practice ? <Banner>{language.Experiment.practice}</Banner> : "" }
                                { stimulus.show ? <Stimulus>{stimulus.value}</Stimulus> : ""}
                                { !stimulus.show && stimulus.count>1 ? <Keypad stimulus={stimulus}
                                                        is_practice={is_practice}
                                                        onClick={handleAnswer} 
                                                /> :"" }
                            </>),
                thankyou: <Stimulus>{language.Experiment.thankyou}</Stimulus>
              }[experimentState]}
            </div>
          </ThemeProvider>
      </StylesProvider>
    </Fragment>
    )
}

export {ApsatVideoPage, ApsatAudioPage} ;