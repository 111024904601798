import React, {useState, useEffect} from 'react';
import {StylesProvider, makeStyles, Button, StepLabel} from '@material-ui/core';
import DotsRow from './DotsRow';
import styled from 'styled-components';
function StyledNextButton(props) {
  return (
    <BottomSection style={{width: `max(280px,${props.width}`}}>
      <NextButton
        size="lg"
        block
        onClick={props.handleNext}
        font={props.language.fonts.med}
      >
        {props.text}
      </NextButton>
      {props.dotsDisable ? null : (
        <DotsRow
          switchSlide={props.onSwitch}
          current={props.currentSlide}
          dotsCount={props.dotsCount ? props.dotsCount : 5}
        />
      )}
    </BottomSection>
  );
}
export default StyledNextButton;
const BottomSection = styled.div`
  top: 83vh;
  position: absolute;
`;

const NextButton = styled.button`
  width: max(280px, 100%);

  height: 5vh;
  border-radius: 5px;
  background-color: white;
  color: #000000;
  border: 0;
  font-size: 2.2vmin;
  font-family: ${(props) => props.font};
  &:hover {
    background-color: white;
    color: #000000;
  }
`;
