import React, { Fragment } from "react";
import styled from "styled-components";
import StyledTextField from "../StyledTextField";
const LoginButton = styled.button`
  width: 24.2vw;
  height: 4.5vh;
  border-radius: 5px;
  background-color: #47cdd9 !important;
  color: #000000;
  border: 0;
  font-size: 2.2vmin;
  font-family: ${(props) => props.font};
  &:hover {
    background-color: #47cdd9;
    color: #000000;
  }
`;

function LoginForm(props) {
  return (
    <Fragment>
      <StyledTextField
        style={{ position: "absolute", top: "14.8vh", marginTop: 0 }}
        variable={props.email}
        type="email"
        label={props.language.Login.email_label}
        updateVariable={props.updateEmail}
        language={props.language}
      />
      <StyledTextField
        style={{ position: "absolute", top: "28.8vh", marginTop: 0 }}
        variable={props.password}
        type="password"
        label={props.language.Login.password_label}
        updateVariable={props.updatePassword}
        language={props.language}
      />

      <LoginButton
        style={{
          position: "absolute",
          top: "41vh",
          fontFamily: props.language.fonts.med,
          fontSize: "2.2vmin",
        }}
        font={props.language.fonts.med}
        size="lg"
        block
        type="submit"
        onClick={props.handleSubmit}>
        {props.language.Login.login_button}
      </LoginButton>
    </Fragment>
  );
}
export default LoginForm;
