import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export default function SimpleModal(props) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.main_modal}>
                <div className={classes.modal_header}>Whoops!!!</div>
                <div
                    className={classes.column}
                    style={{ alignItems: "left !important" }}
                >
                    <p className={classes.modal_text}>{props.text}</p>
                    <button
                        className={classes.ok_button_modal}
                        onClick={props.handleClose}
                    >
                        OK, Got it
                    </button>
                </div>
            </div>
            <SimpleModal />
        </div>
    );

    return (
        <div>
            <Modal open={props.open}>{body}</Modal>
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: "31.4vw",
        height: "39vh",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
    },
    modal_header: {
        fontSize: "2.8vh",
        color: "#245080",
        fontFamily: "robotobold",
    },
    modal_text: {
        fontSize: "2vmin",
        color: "#245080",
        fontFamily: "robotoreg",
    },
    main_modal: {
        paddingTop: "6.7vh",
        paddingLeft: "2.8vw",
        paddingRight: "2.8vw",
        width: "25.8vw",
        height: "29.5vh",
        lineHeight: "150%",
    },
    ok_button_modal: {
        position: "absolute",
        bottom: "2.8vw",
        backgroundColor: "#1763A3",
        color: "white",
        width: "25.8vw",
        height: "5.7vh",
        fontFamily: "robotoreg",
        fontSize: "2.2vmin",
        "&:hover": {
            backgroundColor: "#1763A3",
            color: "white",
        },
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
    },
}));
