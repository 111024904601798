import React, {useState, useEffect} from 'react';
import { StylesProvider, jssPreset, ThemeProvider, createTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {he, en} from '../Text';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

function createData(name, is_patient, is_admin, is_researcher) {
  return { name, is_patient, is_admin, is_researcher, is_enabled:true };
}
const rows = [
  createData('Frozen yoghurt', true, false, false),
  createData('Ice cream sandwich', true, false, false),
  createData('Eclair', false, true, true),
  createData('Cupcake', true, false, true),
  createData('Gingerbread', true, false, false),
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#47cdd9",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Exit = styled.div`
  position: absolute;
  top: 3vh;
  right: 3vh;
  cursor:pointer;
  color:white;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 10vh;
  width: 100%;
`;

const UsersPage = (props) => {
  const [language, setLanguage] = useState(he);
  const [currentSlide, setCurrentSlide] = useState(0);

  const theme = createTheme({
      direction: language == en ? 'ltr' : 'rtl',
    });
    
  const redirectRoute = (route, data = {}) => {
      props.history.push({
        pathname: route,
        state: data,
      });
    }  

    return (
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <div className="wide-box fade-middle" dir={language == en ? "ltr" : "rtl"}>
          <div className="row" style={{justifyContent: 'flex-start', width: ' max(280px, 100%)'}} >
                <Exit onClick={() => redirectRoute('/')} >{language.UsersTable.exit}</Exit>
          </div>
          <Wrapper>
          <TableContainer component={Paper}>
            <Table className="table" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{language.UsersTable.name}</StyledTableCell>
                  <StyledTableCell align="right">{language.UsersTable.test_subject}</StyledTableCell>
                  <StyledTableCell align="right">{language.UsersTable.admin}</StyledTableCell>
                  <StyledTableCell align="right">{language.UsersTable.researcher}</StyledTableCell>
                  <StyledTableCell align="right">{language.UsersTable.enabled}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        indeterminate={false}
                        checked={row.is_patient}
                        onChange={()=>{}}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </StyledTableCell>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        indeterminate={false}
                        checked={row.is_admin}
                        onChange={()=>{}}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </StyledTableCell>                    
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        indeterminate={false}
                        checked={row.is_researcher}
                        onChange={()=>{}}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </StyledTableCell>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        indeterminate={false}
                        checked={row.is_enabled}
                        onChange={()=>{}}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </StyledTableCell>                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Wrapper>
          </div>
        </ThemeProvider>
      </StylesProvider>
    );
  };
  
  export default UsersPage;