import React from "react";
import styled from "styled-components";
const Container = styled.div`
  position: absolute;
  top: 47.5vh;
  display: flex;
  width: 24.2vw;
  flex-direction: row;
  justify-content: space-between;
`;
const StyledButton = styled.button`
  letterspacing: 0.04em;
  background-color: inherit;
  color: white !important;
  border-width: 0;
  font-size: 1.8vmin !important;
  font-family: ${(props) => props.font} !important;
`;
function BottomSection(props) {
  return (
    <Container>
      <StyledButton font={props.language.fonts.reg} onClick={props.onForgot}>
        <div>{props.language.Login.forgot_password}</div>
      </StyledButton>
      <StyledButton font={props.language.fonts.reg} onClick={props.onRegister}>
        {props.language.Login.register}
      </StyledButton>
    </Container>
  );
}
export default BottomSection;
