import React, { useState } from "react";
import { TextField, makeStyles, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import styled from "styled-components";
import { he } from "../Text";

function StyledTextField(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const isPassword =
    props.type === "password" ? (
      <InputAdornment position="end">
        <StyledIcon aria-label="toggle password visibility" onClick={handleClickShowPassword}>
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </StyledIcon>
      </InputAdornment>
    ) : null;
  return (
    <TextField
      error={props.error}
      helperText={props.helperText}
      style={props.style}
      InputLabelProps={{
        style: {
          color: "white",
          fontFamily: props.language.fonts.light,
          fontSize: "2.5vmin",
          marginBottom: "1.5vh",
          marginLeft: "0",
          textAlign: props.language == he ? "right" : "left",
        },
      }}
      InputProps={{
        endAdornment: isPassword,
        classes: {
          underline: classes.underline,
        },
        style: {
          marginTop: "3.5vh",
          color: "white",
        },
      }}

      
      label={props.label}
      type={showPassword ? "text" : props.type}
      value={props.variable}
      onChange={(e) => props.updateVariable(e.target.value)}
    />
  );
}
export default StyledTextField;
const useStyles = makeStyles((theme) => ({
  underline: {
    borderBottomColor: "white !important",
    borderWidth: "1px !important",
    width: "24.2vw !important",
    minWidth: "200px",
    transform: "none !important",
    "&:before": {
      borderBottomColor: "white !important",
      borderWidth: "1px  !important",
      width: "24.2vw !important",
      minWidth: "200px",
      transform: "none !important",
    },
    "&:after": {
      borderBottomColor: "white !important",
      borderWidth: "1px !important",
      width: "24.2vw !important",
      minWidth: "200px",
      transform: "none !important",
    },
  },
}));
const StyledIcon = styled(IconButton)`
  width: 2vmin;
  height: 2vmin;
  color: white !important;
  &:hover {
    width: 2vmin;
    height: 2vmin;
  }
`;
