import React from "react";

function DotsRow(props) {
  let selected = props.current;
  const dots = Array.from(Array(props.dotsCount).keys());
  return (
    <div className="dots-row" style={props.style}>
      {dots.map((dotNumber) => (
        <div
          key={dotNumber}
          onClick={() => props.switchSlide(dotNumber)}
          className={selected == dotNumber ? "dot active" : "dot"}></div>
      ))}
    </div>
  );
}
export default DotsRow;
